<template>
  <BCard
    body
    body-class="space-y-[24px]"
  >
    <div class="flex">
      <BButton
        variant="primary"
        size="sm"
        class="mr-1"
        @click="$router.push('/admin-dashboard-komship')"
      >
        <feather-icon
          size="1x"
          icon="ChevronLeftIcon"
        />
      </BButton>
      <div class="text-black text-2xl font-semibold">
        Detail Ekspedisi
      </div>
    </div>
    <div class="flex gap-[12px]">
      <template v-for="expedition in expeditionList">
        <div
          :key="expedition.value"
          class="text-[16px] px-[24px] py-[10px] cursor-pointer rounded-[27px]"
          :class="
            $route.params.ekspedisi === expedition.value
              ? 'font-bold text-white bg-[#ff5722]'
              : 'bg-gray-300 text-[#222222]'
          "
          @click="handlerSwitchShipping(expedition.value)"
        >
          {{ expedition.label }}
        </div>
      </template>
    </div>
    <div class="flex justify-end items-center">
      <div class="flex">
        <BFormSelect
          v-model="payment"
          :options="paymentMethod"
          class="w-auto mr-[5px]"
        />
        <MonthlyPicker
          v-model="month"
          :month-labels="LABELMONTH"
          :max="maxDatePicker"
          date-format="MMM YYYY"
          :clear-option="false"
          input-class="custom-select"
        />
      </div>
    </div>
    <BOverlay
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <BTable
        :fields="fields"
        :items="items"
        responsive
        class="border-b"
      >
        <template #cell(shipping)="data">
          <div class="">
            {{ data.item.shipping }} - {{ data.item.shipment_alias }}
          </div>
        </template>
        <template #cell(total_shipping_cost)="data">
          <div class="">
            {{ IDR(data.item.total_shipping_cost) }}
          </div>
        </template>
      </BTable>
    </BOverlay>
  </BCard>
</template>

<script>
import MonthlyPicker from 'vue-monthly-picker'
import { IDR } from '@/libs/currency'
import {
  LABELMONTH,
  START_DATE_OF_MONTH,
  END_DATE_OF_MONTH,
} from '@/libs/filterDate'
import { komshipAxiosIns } from '@/libs/axios'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { paymentMethod, fields } from './config'

export default {
  components: { MonthlyPicker },
  data() {
    return {
      IDR,
      shipping_name: this.$route.params.ekspedisi.toUpperCase(),
      expeditionList: [
        { label: 'JNE', value: 'jne' },
        { label: 'IDExpress', value: 'idexpress' },
        { label: 'SAP', value: 'sap' },
      ],
      LABELMONTH,
      START_DATE_OF_MONTH,
      END_DATE_OF_MONTH,
      payment: this.$route.params.payment,
      month: this.$route.params.filterDate,
      paymentMethod,
      fields,
      loading: false,
      items: [],
    }
  },
  computed: {
    maxDatePicker() {
      return this.$moment().endOf('month')
    },
  },
  watch: {
    month: {
      handler() {
        this.fetchData()
      },
    },
    payment: {
      handler() {
        this.fetchData()
      },
    },
    shipping_name: {
      handler() {
        this.fetchData()
      },
    },
  },
  created() {
    this.fetchData()
    this.fetchExpeditionList()
  },

  methods: {
    handlerSwitchShipping(url) {
      this.shipping_name = (url)
      this.$router.push(`/top-ongkir/detail/${url}`)
    },
    fetchData() {
      this.loading = true
      const url = '/v1/admin/top-shipping-cost'
      const params = `shipping_name=${
        this.shipping_name
      }&start_date=${START_DATE_OF_MONTH(
        this.month,
      )}&end_date=${END_DATE_OF_MONTH(this.month)}&payment_method=${
        this.payment
      }`
      komshipAxiosIns
        .get(`${url}?${params}`)
        .then(res => {
          const { data } = res.data
          this.items = data
          this.loading = false
        })
        .catch(err => {
          this.$toast(
            {
              component: ToastificationContentVue,
              props: {
                title: 'Gagal',
                text: 'Gagal mengambil data, coba lagi nanti',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            },
            2000,
          )
        })
    },
  },
}
</script>
