/* eslint-disable import/prefer-default-export */
export const paymentMethod = [
  {
    text: 'COD',
    value: 'COD',
  },
  {
    text: 'Non-COD',
    value: 'BANK TRANSFER',
  },
  {
    text: 'COD & Non-COD',
    value: '',
  },
]

export const fields = [
  {
    key: 'shipping',
    label: 'Layanan Ekspedisi',
    thClass: 'font-semibold text-[#626262] !capitalize',
    tdClass: 'text-[#222222]',
  },
  {
    key: 'total_order',
    label: 'Order',
    thClass: 'font-semibold text-[#626262] !capitalize',
    tdClass: 'text-[#222222]',
  },
  {
    key: 'total_user',
    label: 'User',
    thClass: 'font-semibold text-[#626262] !capitalize',
    tdClass: 'text-[#222222]',
  },
  {
    key: 'total_shipping_cost',
    label: 'Nilai Ongkir',
    thClass: 'font-semibold text-[#626262] !capitalize',
    tdClass: 'text-[#222222]',
  },
]
